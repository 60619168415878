import { gsap, Expo } from "gsap";

export const menu = {
  navOverlay: document.querySelector("#nav_overlay"),
  menuContainer: document.querySelector("#nav_overlay #menu-menu-principal"),
  buttonMenu: document.querySelector("#wrapper-navbar a.burger"),
  buttonClose: document.querySelector("#nav_overlay a.closebtn"),
  initialState: {},
  init() {
    // console.log("HDL / update menu");
    if (this.buttonMenu) this.buttonMenu.onclick = () => this.openMenu();
    if (this.buttonClose) this.buttonClose.onclick = () => this.closeMenu();
  },
  openMenu() {
    gsap.to(this.navOverlay, {
      duration: 0.3,
      height: "100vh",
      ease: Expo.easeInOut,
    });
    this.displayMenuItem();
    this.displayContent();
  },

  closeMenu() {
    gsap.to(this.navOverlay, {
      duration: 0.15,
      height: 0,
      ease: Expo.easeIn,
    });
  },
  displayMenuItem() {
    let menuItems = document.querySelectorAll(".overlay_menu li");
    gsap.from(menuItems, {
      duration: 0.5,
      stagger: 0.25,
      opacity: 0,
      delay: 0.3,
      ease: Expo.easeOut,
      x: "-50px",
    });
  },
  displayContent() {
    let content = document.querySelectorAll(".overlay-content p");
    gsap.from(content, {
      duration: 0.5,
      stagger: 0.25,
      opacity: 0,
      delay: 1,
      ease: Expo.easeOut,
      x: "-50px",
    });
  },
};
