import { menu } from "./js/menu";
import { headerPage } from "./js/headerPage";
import { actualite } from "./js/actualite";
import { chambrePage } from "./js/chambrePage";
import { quartierComp } from "./js/quartierComp";
import { carouselComp } from "./js/carouselComp";
import Aos from "aos";

const main = {
  init() {
    // this.aosSurchargeToBlock();

    document.addEventListener("DOMContentLoaded", () => {
      // console.log("HDL / DOMContentLoaded");
      this.start();
    });
  },
  start() {
    menu.init();
    headerPage.init();
    actualite.init();
    chambrePage.init();
    quartierComp.init();
    // carouselComp.init();
  },

  aosSurchargeToBlock() {
    Aos.init({
      disable: true,
      startEvent: "DOMContentLoaded",
      disableMutationObserver: true,
    });
  },
};

main.init();

