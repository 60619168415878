import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const carouselComp = {
  init() {
    let items = document.querySelectorAll(".hotel_inner, .chambres__inner");
    items.forEach((item) => new CarouselItem(item));
  },
};

class CarouselItem {
  constructor(el) {
    this.DOM = { el: el };
    console.log("carousel", this.DOM.el);

    gsap.to( ".carousel", {
      yPercent: -20,
      ease: "none",
      scrollTrigger: {
        trigger: this.DOM.el,
        scrub: true,
      },
    });
  }
}
