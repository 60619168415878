import { gsap, Expo } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const headerPage = {
  pages: {
    home: document.querySelector("body.home"),
    default: document.querySelector("body.page-template-default"),
    galerie: document.querySelector("body.page-template-tpl_page_galerie"),
  },
  init() {
    // console.log("HDL / update / animation de la homepage");

    if (this.pages.home || this.pages.default || this.pages.galerie) {
      this.introductionAnimation();
    }
    //ecouteurs
    if (this.pages.default || this.pages.galerie) {
      window.addEventListener("scroll", this.transitionOnScroll);
    }

    this.createNewHeaderWidthBackground();
  },
  createNewHeaderWidthBackground() {
    let header = document.querySelector("#header");
    let attributes = header.attributes;
    let styleOfElement = attributes.style.value;

    let urlOfBackgroundImage = styleOfElement.match(/url(.*)/gm);

    let gradientExist = styleOfElement.match(/linear-gradient/gm);

    let gradientProperties = styleOfElement.match(
      /background-image:(.*),url/gm
    );

    // on initialise l'image du background
    gsap.set("#header", {
      backgroundImage: urlOfBackgroundImage,
      overflow: "hidden",
    });

    if (gradientExist) {
      gradientProperties = gradientProperties
        .toString()
        .replace(/background-image:/, "")
        .replace(/,url/, "");

      // création d'un cache par dessus avec les propriétés
      const cache = document.createElement("div");
      cache.classList.add("header-voile");

      document.querySelector("#header").prepend(cache);

      gsap.set(".header-voile", {
        background: gradientProperties,
        // width: "calc(100% - 14px",
        // position: "absolute",
        // top: 0,
        // left: 0,
        // bottom: "14px",
      });
    }
  },
  introductionAnimation() {
    this.showElementsFirstTime();
  },
  transitionOnScroll(event) {
    // console.log("ntransition")
    var lastPosition = window.scrollY;
    var ticking = false;
    const DELTA = 40;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        if (lastPosition > DELTA) {
          headerPage.hideBackground();
          headerPage.hideElementsOnScroll();
        } else {
          headerPage.showBackground();
          headerPage.showElementsOnScroll();
        }
        ticking = false;
      });
    }
    ticking = true;
  },

  showElementsFirstTime() {
    let ctaProm = document.querySelector(".cta_promo");
    let title = document.querySelectorAll(".baseline.waou");
    let socialShareButtons = document.querySelectorAll(".rs_show");
    let formElements = document.querySelectorAll("#form_reservit div");

    let tl = gsap.timeline({ delay: 1.5 });
    ctaProm &&
      tl.fromTo(
        ctaProm,
        { opacity: 0, x: 50 },
        { opacity: 1, duration: 0.5, ease: Expo.easeOut }
      );
    title &&
      tl.fromTo(
        title,
        { opacity: 0, y: "-5px" },
        { opacity: 1, y: 0, duration: 0.25, stagger: 0.2, delay: -0.2 }
      );
    socialShareButtons &&
      tl.fromTo(
        socialShareButtons,
        { opacity: 0, x: "-25px" },
        { duration: 0.5, opacity: 1, x: 0, delay: -0.3, stagger: 0.2 }
      );

    formElements &&
      tl.fromTo(
        formElements,
        { opacity: 0, y: "40px" },
        { duration: 0.5, opacity: 1, y: 0, stagger: 0.2 }
      );
  },
  hideElementsOnScroll() {
    gsap.set("#form_reservit div:last-child", {opacity : 0});
    gsap.to([".baseline.waou", ".cta_promo", "#form_reservit div"], {
      opacity: 0,
      duration: 0.2,
      stagger: 0.05,
    });
    // gsap.to('.header',{background: "white"})
  },
  showElementsOnScroll() {
    gsap.to([".baseline.waou", ".cta_promo", "#form_reservit div"], {
      opacity: 1,
      duration: 0.2,
      stagger: 0.05,
    });
  },
  hideBackground() {
    gsap.to([".header-voile"], {
      opacity: 0,
      duration: 0.3,
    });
  },
  showBackground() {
    gsap.to([".header-voile"], {
      opacity: 1,
      duration: 0.6,
    });
  },
};
