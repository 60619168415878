import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const actualite = {
  init() {
    // console.log("HDL / template actualite");

    if (document.querySelector(".articles") || document.querySelector(".articles_relatifs")) {
      this.showArticles();
    }

    if (document.querySelector(".article_card")) {
      this.redefineLinksContainer();
    }
  },
  showArticles() {
    // console.log("HDL / voir les articles un par un");
    let cards = document.querySelectorAll(".article_card");

    if (cards)
      cards.forEach((element, index) => {
        gsap.from(element, {
          scrollTrigger: { trigger: element, start: "50px 90%" },
          opacity: 0,
          duration: 0.7 ,
          delay : index / 10,
          y: "100px",
        });
      });
  },
  redefineLinksContainer() {
    // console.log("HDL / redefinie les liens autour de tout le push");
    let cards = document.querySelectorAll(".article_card");

    cards.forEach((element) => {
      let link = element.querySelector(".section_cta a");
      element.addEventListener(
        "click",
        function () {
          // console.log(link.href);
          window.location = link.href;
        },
        false
      );
    });
  },
};
