import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const chambrePage = {
  init() {
    if (document.querySelector("body.les-chambres")) {
      // console.log("HDL // c'est une page de type chambre");
      this.animationFirstSlide();
      this.animationOtherSlides();
    }
  },

  animationOtherSlides() {
    let titles = document.querySelectorAll("h2.section_h2");
    let chambre_descriptif = document.querySelectorAll("p.chambre_descriptif");

    gsap.set([titles, chambre_descriptif], { opacity: 0, x: "25px" });

    [...titles].forEach((el) => {
      gsap.to(el, {
        scrollTrigger: { trigger: el, toggleActions: "restart" },
        opacity: 1,
        x: 0,
        duration: 0.3,
        delay: 0.25,
        ease : Expo.easeOut
      });
    });
    [...chambre_descriptif].forEach((el) => {
      gsap.to(el, {
        scrollTrigger: { trigger: el, toggleActions: "restart" },
        opacity: 1,
        x: 0,
        duration: 0.3,
        delay: 0.5,
        ease : Expo.easeOut
      });
    });
  },

  animationFirstSlide() {
    // console.log("HDL // animation first slide chambres");

    let title = document.querySelector("h1.header_titre");
    let items = document.querySelectorAll(
      ".header_chambres.menu_fixed .section_cta"
    );

    let tl = gsap.timeline({ delay: 1.5 });
    tl.fromTo(
      title,
      { opacity: 0, y: "-5px" },
      { opacity: 1, y: 0, duration: 0.25, stagger: 0.2, delay: -0.2 }
    );
    tl.fromTo(
      items,
      {
        opacity: 0,
        y: "-25px",
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.3,
      }
    );
  },
};

export { chambrePage };
