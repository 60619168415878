import { gsap, Linear } from "gsap";

const quartierComp = {
  init() {
    this.DOM = { el: document.querySelector("#quartier") };
    if (this.DOM.el) {
      let zoneInteractiveOpen = document.querySelectorAll(
        "[onclick^=openMonuments]"
      );

      gsap.set("#fiches_wrapper", { opacity: 0 });

      zoneInteractiveOpen.forEach((area) => {
        area.addEventListener("click", function () {
          // console.log("click zone interaction");

          gsap.to("#fiches_wrapper", {
            opacity: 1,
            delay: 0.2,
            ease: Linear.easeOut,
            duration: 0.1,
          });
        });
      });

      document
        .querySelector("[onclick^=closeMonuments]")
        .addEventListener("click", function () {
          gsap.to("#fiches_wrapper", { opacity: 0, duration: 0.2 });
        });
    }
  },
};

export { quartierComp };
